import React from 'react'

import Header from '../Components/Header.js'

import styles from './Blog.module.css'

function Blog() {
    return (
        <div>
            <Header />
        </div>
    )
}

export default Blog
